import React from 'react'
import Layout from 'layouts/fr'
import styled from 'styled-components'
import design from 'design'
import { Title, Text, Row, Div, Box, Button } from 'components'
import { TryOrSchedule } from 'containers/fr'

export default () => {
  return (
    <Layout p={5}>
      <Div pt={[6,8]} px={[0,6]} textAlign={['center', 'left']}>
        <Title mb={5} fontSize={[7,9]}>À propos de Stairlin</Title>
        <Text tag={'h2'} mb={7} fontSize={[6,8]}>La mission de Stairlin est de proposer une solution clé en main pour les indépendants afin qu’ils puissent créer, gérer et développer leur entreprise. </Text>
        <Text mb={5} fontSize={[3,5]} textAlign={'justify'}>Stairlin a été fondé en 2017 à Lausanne par Thibault Dubuit et Bastien Gysler. Les deux fondateurs ont été abasourdis par le fait qu’il était plus facile de réserver un hôtel ou un billet d’avion que de prendre rendez-vous chez le coiffeur du coin. </Text>
        <Text mb={5} fontSize={[3,5]} textAlign={'justify'}>Tous les indépendants rencontrent les mêmes problématiques. Une charge administrative élevée et redondante, des retards de paiements, des rendez-vous manqués et pas assez de temps pour ce qui compte vraiment.</Text>
        <Text mb={5} fontSize={[3,5]} textAlign={'justify'}>Afin de résoudre ces problématiques récurrentes, Stairlin propose une suite d’outils intuitifs et conçus spécifiquement pour les entrepreneurs solos. L’objectif est d'accroitre leur productivité, de réduire leur charge de travail administrative, de leur donner une vue globale sur leur entreprise avec comme finalité d’améliorer leur quotidien afin qu’ils aient plus de temps pour leur famille et pour leurs loisirs.</Text>
        <Text fontSize={[3,5]}></Text>
        <Title mt={[8,7]} mb={[6,7]} fontSize={[7,8]}>Fondateurs</Title>

        <Div display={['block', 'flex']} _justifyContent={'space-between'}>
          <Div flex={1} mr={[0,6]} _mb={[6,8]}>
            <Title mb={4} fontSize={[6,7]}>Bastien Gysler</Title>
            <Text mb={[5,6]} fontSize={6} color={'grayDark'}>CEO</Text>
            <Text fontSize={[3,5]} textAlign={'justify'}>Bastien est co-fondateur de Stairlin. Son gout pour le design, son envie de construire des produits à l’aide de technologies de pointe, sa sensibilité aux thématiques de sécurité et une petite touche de paranoïa, font de Bastien la personne idéale pour se charger du développement de notre solution.
Il croit sincèrement que la technologie peut simplifier notre vie de tous les jours. En tant qu’ingénieur backend et en charge du développement du produit, Bastien développe Stairlin car personne ne le fait proprement et ça le rend tout simplement dingue. Avant de créer Stairlin, Bastien a aiguisé ses talents d’ingénieur au sein de plusieurs sociétés de technologie en Suisse. Bastien a un Bachelor en science en informatique de gestion de la HES SO.</Text>
          </Div>

          <Div mt={[6,0]} flex={1} _mb={[6,8]}>
            <Title mb={4} fontSize={[6,7]}>Thibault Dubuis</Title>
            <Text mb={[5,6]} fontSize={6} color={'grayDark'}>Président / Partenariats stratégiques</Text>
            <Text fontSize={[3,5]} textAlign={'justify'}>Thibault est co-fondateur de Stairlin. Ses passions variées et éclectiques lui procurent un talent particulier pour trouver des façons innovantes de résoudre les problèmes rencontrés au quotidien. Grâce à son parcours professionnel, son avidité à apprendre de nouvelles choses et juste la bonne dose de paresse pour être efficace, Thibault se charge de nos relations avec la clientèle, nos partenaires et investisseurs.
Avant de créer Stairlin, Thibault a commencé sa carrière auprès de la banque anglaise HSBC et travaillait pour le CFO d’une des plus grosses entreprises de Fintech en Suisse. Thibault a un Bachelor de l’Université de St. Gall et est un “Chartered Alternative Investment Analyst”.</Text>
          </Div>
        </Div>

        <Title mt={[8,7]} mb={[6,7]} fontSize={[7,8]}>Advisory Board</Title>
        <Div display={['block', 'flex']} _justifyContent={'space-between'}>
          <Div flex={1} mr={[0,6]} mb={[6,8]}>
            <Title mb={4} fontSize={[6,7]}>Patrick Botteron</Title>
            <Text fontSize={[3,5]} textAlign={'justify'}>Patrick Botteron est un « business angel » expérimenté, mais aussi à la tête du « private banking » de la Banque Cantonale Vaudoise. Ce dernier nous aide dans notre développement grâce à sa grande expérience de « business angel » et à son sens aigu des affaires.</Text>
          </Div>

          <Div flex={1} mb={[8,8]}>
            <Title mb={4} fontSize={[6,7]}>Patrick Zbinden</Title>
            <Text fontSize={[3,5]} textAlign={'justify'}>Patrick Zbinden est codirecteur de la gestion d'actifs pour EFG International, une banque privée cotée en bourse suisse. Il nous aide à consolider notre « business case » et s’assure que les bases de nos assises financières soient saines.</Text>
          </Div>
        </Div>

        <TryOrSchedule />
      </Div>
    </Layout>
  )
}
